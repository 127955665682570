import React, { useState } from 'react';
import {
    useNavigate
} from "react-router-dom";
import Axios from 'axios'
import Cookies from 'js-cookie'


function Login() {

    const [username, setUsername] = useState<string>()
    const [userpass, setUserpass] = useState<string>()
    const navigate = useNavigate();
    document.title = "Login"


    function handleLogin() {
        if(username == "sonoco" && userpass == "thefair"){
            Axios.get("https://api.sonomacountyfair.com//api/login/")
            .then((response)=>{
                const cookieSet = Cookies.set("token", response.data.token, {
                    expires: .4
                })
                navigate("/view-events")
            })
        }else{
            alert("Wrong Credentials ")
        }
    }


  return (
    <div className='my-10 flex flex-col w-full items-center justify-center' >
            <input 
            className='my-2 border px-2 py-1 rounded'
            onChange={((e)=>{
                setUsername(e.target.value)
            })} placeholder='Login' type="text" name="" id="" />
            <input 
            className='my-2 border px-2 py-1 rounded'
            onChange={((e)=>{
                setUserpass(e.target.value)
            })} type="password" placeholder='Password' name="" id="" />

            <button
            className='btn bg-green-500 px-3 py-1 text-white font-bold rounded m-2 hover:bg-green-600'
            onClick={(()=>{
                handleLogin()
            })}
            >Submit</button>
    </div>
  );
}

export default Login;
