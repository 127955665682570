import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'js-cookie'
import Navbar from './Navbar';
 
export type Events = {
  event_name: string,
  event_dates: string,
  event_day: string,
  event_month: string,
  event_year: string,
  status: number,
}

function UpdateEvent() {

  let data = []
  const {id} = useParams();
  const navigate = useNavigate()
  const [info, setInfo] = useState<Events[] | null>()


  const [event_name, setEventName] = useState<string>()
  const [event_day, setEventDay] = useState<number>() ;
  const [event_month, setEventMonth] = useState<number>() ;
  const [event_year, setEventYear] = useState<number>() ;
  const [hours, setHours] = useState<string>();
  const [location, setLocation] = useState<string>() ;
  const [organization, setOrganizations] = useState<string>() ;
  const [phone, setPhone] = useState<string>() ;
  const [website, setWebsite] = useState<string>();
  const [subtitle, setSubtitle] = useState<string>();
  const [admission, setAdmission] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [status, setStatus] = useState<number>();

  function handleDelete(){
    Axios.delete(`https://api.sonomacountyfair.com/api/deleteEvent/${id}`)
    .then((response)=>{
      navigate("/view-events")
    })
  }

  function handleUpdate() {
    if(event_name && event_day && event_month && event_year && hours && phone && website && comment){
      Axios.put(`https://api.sonomacountyfair.com/api/updateevent/${id}`,{
      event_name: event_name,
      event_dates: event_month+"/"+event_day+"/"+event_year,
      event_day: event_day,
      event_month: event_month,
      event_year: event_year,
      hours: hours,
      location: location,
      organization: organization,
      phone: phone,
      website: website,
      subtitle: subtitle,
      admission: admission,
      contact: contact,
      comment: comment,
      status: status
    }).then((response)=>{
      navigate("/view-events")
    })
    }else{
      alert("Missing information")
    }
  }

  const loadEvent = ()=>{
    Axios.get(`https://api.sonomacountyfair.com/api/eventinfo/${id}`)
    .then((response)=>{
      data = response.data[0]
      setEventName(data.event_name)
      setEventDay(data.event_day)
      setEventMonth(data.event_month)
      setEventYear(data.event_year)
      setHours(data.hours)
      setLocation(data.location)
      setOrganizations(data.organization)
      setPhone(data.phone)
      setWebsite(data.website)
      setSubtitle(data.subtitle)
      setAdmission(data.admission)
      setContact(data.contact)
      setComment(data.comment)
      setStatus(data.status)
    })
}

  useEffect(() => {
    document.title = `Update ${event_name}`
    Axios.get("https://api.sonomacountyfair.com/api/checklogin",{
        headers: {
            "x-access-token": Cookies.get("token")
        }
    }).then((response)=>{
        if(response.data.loggedIn != true){
            navigate("/")
        }else{
            loadEvent()
        }
    })
}, []);


    
  return (
    <div>
        <Navbar/>
        <div className='flex items-start justify-center'>
          <div className='mt-10 flex flex-col items-start justify-start'>
            <div className='flex w-full justify-between'>
              <h2 className='
              text-4xl
              '>Update Event</h2>
              <div>
                <button
                onClick={(()=>{
                  handleDelete()
                })} 
                className='bg-red-500 text-white rounded p-1 hover:bg-red-600'>Delete</button>

              </div>
            </div>

              <input value={event_name}
              onChange={((e)=>{
                setEventName(e.target.value)
              })} type="text" placeholder='Event Name' className='my-2 mx-1 w-full border border-slate-300 p-1 rounded'/>
              <input value={subtitle}
              onChange={((e)=>{
                setSubtitle(e.target.value)
              })} type="text" placeholder='Subtitle' className='my-2 mx-1 w-full border border-slate-300 p-1 rounded'/>
              <textarea  value={comment}
              onChange={((e)=>{
                setComment(e.target.value)
              })} placeholder='Description' className='my-2 mx-1 w-full border border-slate-300 p-1 rounded resize-none h-24' />
                <h3>Date</h3>
              <div className="date flex flex-row">
                <input value={event_month} 
                onChange={((e)=>{
                  setEventMonth(e.target.valueAsNumber)
                })} type="number" placeholder='Month' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input 
                  value={event_day}
                  onChange={((e)=>{
                    setEventDay(e.target.valueAsNumber)
                  })} type="number" placeholder='Day' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input value={event_year} 
                  onChange={((e)=>{
                    setEventYear(e.target.valueAsNumber)
                  })} type="number" placeholder='Year'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded' />
              </div>
                <h3>Time</h3>
              <div>
                <input value={hours} 
                onChange={((e)=>{
                  setHours(e.target.value)
                })} type="text" placeholder='Hours' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>

              </div>
                <h3>Contact</h3>
                <div>
                  <input value={location}
                  onChange={((e)=>{
                    setLocation(e.target.value)
                  })} type="text" placeholder='Location' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input value={organization}
                  onChange={((e)=>{
                    setOrganizations(e.target.value)
                  })} type="text" placeholder='Organization'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                </div>
              <div>
                <div>
                <input value={contact}
                onChange={((e)=>{
                  setContact(e.target.value)
                })} type="text" placeholder='Contact' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                <input value={phone}
                onChange={((e)=>{
                  setPhone(e.target.value)
                })} type="text" placeholder='Phone' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                </div>
              </div>
              <div>
                <input value={website}
                onChange={((e)=>{
                  setWebsite(e.target.value)
                })} type="text" placeholder='Website'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>

              <input value={admission}
              onChange={((e)=>{
                setAdmission(e.target.value)
              })} type="text" placeholder='Admission' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>

              </div>





              <div className='w-full'>
              <h4 className='font-bold mt-2'>
                Status</h4>
                <br></br>
                <div className='w-full flex align-center justify-center '>
                    Not Live
                    <input type="range" max={1} min={0} 
                    className="w-8 mx-2"
                    onChange={((e)=>{
                    setStatus(e.target.valueAsNumber)
                    })} value={status} /> Live
                </div>

              </div>
              <button onClick={(()=>{
                handleUpdate()
              })} 
              className='btn bg-green-500 px-3 py-1 text-white font-bold rounded m-2 hover:bg-green-600'
              > Submit </button>

          </div>
      </div>
    </div>
  );
}

export default UpdateEvent;
