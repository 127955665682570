import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Navbar from './Navbar';


function CreateEvent() {

  const navigate = useNavigate()

  const [event_name, setEventName] = useState<string>()
  const [event_day, setEventDay] = useState<number>() ;
  const [event_month, setEventMonth] = useState<number>() ;
  const [event_year, setEventYear] = useState<number>() ;
  const [hours, setHours] = useState<string>();
  const [location, setLocation] = useState<string>() ;
  const [organization, setOrganizations] = useState<string>() ;
  const [phone, setPhone] = useState<string>() ;
  const [website, setWebsite] = useState<string>();
  const [subtitle, setSubtitle] = useState<string>();
  const [admission, setAdmission] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [status, setStatus] = useState<string>("1");


  useEffect(() => {
    document.title = "Create Event"
    Axios.get("https://api.sonomacountyfair.com/api/checklogin",{
        headers: {
            "x-access-token": Cookies.get("token")
        }
    }).then((response)=>{
        if(response.data.loggedIn != true){
            navigate("/")
        }
    })
}, []);


  function handleSubmit() {
    if(event_name && event_day && event_month && event_year && hours && phone && website && comment){
      Axios.post('https://api.sonomacountyfair.com/api/insertEvent/',{
      event_name: event_name,
      event_dates: event_month+"/"+event_day+"/"+event_year,
      event_day: event_day,
      event_month: event_month,
      event_year: event_year,
      hours: hours,
      location: location,
      organization: organization,
      phone: phone,
      website: website,
      subtitle: subtitle,
      admission: admission,
      contact: contact,
      comment: comment,
      status: status
    }).then((response)=>{
      navigate('/view-events')
    })
    }else{
      alert("Missing information")
    }
  }



  return (
    <div>
      <Navbar/>
      <div className='flex items-start justify-center'>
          <div className='mt-10 flex flex-col items-start justify-start'>
            <h2 className='
            text-4xl
            '>Create Event</h2>
              <input 
              onChange={((e)=>{
                setEventName(e.target.value)
              })} type="text" placeholder='Event Name' className='my-2 mx-1 w-full border border-slate-300 p-1 rounded'/>
              <input onChange={((e)=>{
                setSubtitle(e.target.value)
              })} type="text" placeholder='Subtitle' className='my-2 mx-1 w-full border border-slate-300 p-1 rounded'/>
              <textarea onChange={((e)=>{
                setComment(e.target.value)
              })} placeholder='Description' className='my-2 mx-1 w-full border border-slate-300 p-1 h-24 rounded resize-none' />
                <h3>Date</h3>
              <div className="date flex flex-row">
                <input onChange={((e)=>{
                  setEventMonth(e.target.valueAsNumber)
                })} type="number" placeholder='Month' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input onChange={((e)=>{
                    setEventDay(e.target.valueAsNumber)
                  })} type="number" placeholder='Day' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input onChange={((e)=>{
                    setEventYear(e.target.valueAsNumber)
                  })} type="number" placeholder='Year'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded' />
              </div>
                <h3>Time</h3>
              <div>
                <input onChange={((e)=>{
                  setHours(e.target.value)
                })} type="text" placeholder='Hours' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
              </div>
                <h3>Contact</h3>
                <div>
                  <input onChange={((e)=>{
                    setLocation(e.target.value)
                  })} type="text" placeholder='Location' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input onChange={((e)=>{
                    setOrganizations(e.target.value)
                  })} type="text" placeholder='Organization'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                </div>
              <div>
                <div>
                  <input onChange={((e)=>{
                    setContact(e.target.value)
                  })} type="text" placeholder='Contact' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input onChange={((e)=>{
                    setPhone(e.target.value)
                  })} type="text" placeholder='Phone' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                </div>
                <div>
                  <input onChange={((e)=>{
                    setWebsite(e.target.value)
                  })} type="text" placeholder='Website'className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>
                  <input onChange={((e)=>{
                  setAdmission(e.target.value)
                })} type="text" placeholder='Admission' className='my-2 mx-1 w-64 border border-slate-300 p-1 rounded'/>

                </div>
              </div>

              <button onClick={(()=>{
                handleSubmit()
              })} 
              className='btn bg-green-500 px-3 py-1 text-white font-bold rounded m-2 hover:bg-green-600'
              > Submit </button>

          </div>
      </div>
    </div>
  );
}

export default CreateEvent;
