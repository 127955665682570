import React from 'react';
import './App.css';
import CreateEvent from './Components/CreateEvent';
import Navbar from './Components/Navbar';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import ViewEvents from './Components/ViewEvents';
import Login from './views/Login';
import UpdateEvent from './Components/UpdateEvent';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/create-events" element={<CreateEvent />} />
        <Route path="/view-events" element={<ViewEvents />} />
        <Route path="/update-event/:id" element={<UpdateEvent />} />
        {/* <Route path="/database/:table_id" element={<TableViewer />} />
        <Route path="/edit/:table_id" element={<TableEdit />} /> */}
      </Routes>
  </BrowserRouter>
  );
}

export default App;
