import React from 'react';

function Navbar() {
  return (
    <div>
        <div className='w-full h-18 flex items-center p-2 justify-center flex-col shadow'> 
            <img className='w-44' src="http://www.sonomacountyfair.com/images/EventCenter-WebMasthead.png" alt="" />
            <div className="links my-3">
                <a className='mx-5 hover:text-gray-600' href="/create-events">Create Event</a>
                {/* <a className='mx-5 hover:text-gray-600' href="/view-events">Delete Event</a> */}
                <a className='mx-5 hover:text-gray-600' href="/view-events">View Event</a>
                {/* <a className='mx-5 hover:text-gray-600' href="">Update Event</a> */}
            </div>
        </div>
    </div>
  );
}

export default Navbar;
