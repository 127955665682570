import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import Cookies from 'js-cookie'
import {useNavigate} from 'react-router-dom'
import Navbar from './Navbar';

export type Events = {
    id: number,
    event_name: string,
    event_dates: string,
    event_day: string,
    event_month: string,
    event_year: string,
    status: number,
}

function ViewEvents() {

    const navigate = useNavigate()
    const [table, setTable] = useState<Events[] | null>()
    let data = []

    const loadEvents = ()=>{
        Axios.get('https://api.sonomacountyfair.com/api/getAllEvents/')
        .then((response)=>{
            console.log(response.data)
            data = response.data
            setTable(data)
        })
    }

    useEffect(() => {
        document.title = "View Events"
        Axios.get("https://api.sonomacountyfair.com/api/checklogin",{
            headers: {
                "x-access-token": Cookies.get("token")
            }
        }).then((response)=>{
            if(response.data.loggedIn != true){
                navigate("/")
            }else{
                loadEvents()
            }
        })
    }, []);

  return (
    <div>
        <Navbar/>
        <div className='flex items-center justify-center'>
            <table className='my-10 rounded border border-slate-300 w-1/2 overflow-scroll'>
                <thead className='border border-slate-300'>
                    <tr className='border border-slate-300'>
                        <th className='border border-slate-300'>Event Name</th>
                        <th className='border border-slate-300'>Event Date</th>
                        <th className='border border-slate-300 mx-1'>Live</th>
                        <th className='border border-slate-300 mx-1'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {table? table.map((data)=>{
                        return <tr> 
                                <td style={{resize: "both"}}
                                className='border border-slate-300'>
                                    {data.event_name}
                                </td>
                                <td className='border border-slate-300'>
                                    {data.event_month}/{data.event_day}/{data.event_year}
                                </td>
                                <td className='border border-slate-300 text-center'>
                                    <input className='w-6' min={0} max={1} value={data.status} type="range" />
                                </td>
                                <td className='border border-slate-300 flex flex-row'>
                                <button onClick={(()=>{
                                    navigate(`/update-event/${data.id}`)
                                })} className='bg-green-500 px-2 text-white py-1 m-1 rounded hover:bg-green-600'>Edit</button>
                                </td>
                            </tr>
                    }) : null}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default ViewEvents;
